export default {
  methods: {
   fullPropertyName (propName) {
      let fullPropName = ''
      let propertyName = this.$t(propName)
      if (!propertyName) {
        return ''
      }
      let propNameArray = propName.split('.')
      if (propNameArray.length === 2) {
        fullPropName += (this.formatValue('userLog.className', propNameArray[0]) || this.$t(propNameArray[0] + '.' + propNameArray[0])) + '->'
        fullPropName += propertyName.toLowerCase()
      }
      return fullPropName
    }
  }
}
