<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <FilterBar
          :value="localFilters"
          @input="handleInputFilter"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import controls from '@/components/mixins/controls/controls'
import FilterBar from '@/components/views/admin/log/List/Controls/FilterBar'

export default {
  mixins: [controls],
  name: 'Controls',
  components: { FilterBar }
}
</script>

<style scoped>

</style>
