<template>
  <div class="d-flex align-center">
    <AgentSelect
      v-model="localFilters.userId"
      class="mr-2"
    />
    <DateRange
      dense
      outlined
      hide-details
      class="ml-0"
      v-model="localFilters.createdAt"
    />
    <div class="d-flex">
      <v-btn
        class="ml-10"
        color="primary"
        small
        @click="handleFind"
      >Найти
        <v-icon
          small
          class="ml-2"
        >mdi-magnify
        </v-icon>
      </v-btn>
      <v-btn
        class="ml-1"
        small
        @click="handleClear"
      >Очистить
      </v-btn>
    </div>
  </div>
</template>

<script>
import { cleanClone, deepEqual } from '@/components/utils/common'
import AgentSelect from '@/components/views/admin/user/Detail/AgentSelect'
import DateRange from '@/components/views/admin/advert/Common/Controls/DateRange'
import LogFilter from '@/components/structures/logFilter'

export default {
  name: 'FilterBar',
  components: { AgentSelect, DateRange },
  props: {
    value: Object
  },
  data () {
    return {
      localFilters: new LogFilter()
    }
  },
  watch: {
    value: {
      handler () {
        if (this.value && Object.keys(this.value).length && !deepEqual(this.localFilters, this.value)) {
          this.localFilters = cleanClone(this.value)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleFind () {
      this.$emit('input', cleanClone(this.localFilters))
    },
    handleClear () {
      this.localFilters = new LogFilter()
      this.$emit('input', cleanClone(this.localFilters))
    }
  }
}
</script>

<style scoped>

</style>
