export default [
  {
    text: 'ID',
    align: 'left',
    sortable: false,
    value: 'id',
    width: '80px'
  },
  {
    text: 'Агент',
    align: 'left',
    sortable: true,
    value: 'parent_id',
    width: '240px'
  },
  {
    text: 'Дата',
    align: 'left',
    sortable: true,
    value: 'created_at',
    width: '100px'
  },
  {
    text: 'Действие',
    align: 'left',
    sortable: false,
    value: 'action',
    width: '100px'
  },
  {
    text: 'Сущность',
    align: 'left',
    sortable: false,
    value: 'class_name',
    width: '100px'
  },
  {
    text: 'Id элемента',
    align: 'left',
    sortable: false,
    value: 'entity_id',
    width: '140px'
  },
  {
    text: 'Детали',
    align: 'left',
    sortable: false,
    value: 'logItems'
  }
]
