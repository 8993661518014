<template>
  <div>
    <Controls
      :filters="value.requestParams.filters"
      @input="handleControls($event)"
      @list="$emit('list', $event)"
      :loading="loadingTable"
    />
    <div class="d-flex">
      <Table
        :items="items"
        :options="value.requestParams.options"
        @input="localRequestParams.options = $event"
        :loading="loadingTable"
        :items-length="itemsLength"
        @detail="$emit('detail', $event)"
        class="mt-2 flex-grow-1"
      />
    </div>
  </div>
</template>

<script>
import Controls from './List/Controls'
import Table from './List/Table'
import list from '@/components/mixins/list'

export default {
  mixins: [list],
  name: 'List',
  components: { Controls, Table },
  data () {
    return {
      fillTableUrl: '/log'
    }
  }
}
</script>

<style scoped>

</style>
